<template>
  <div class="container">
    <div class="entry-component">
      <img src="@/assets/img/global/lcf-logo.png" />
      <!-- <Tabs v-model="active">
        <Tab title="登录" name="login">
          <Form class="form" @submit="login">
            <Field
              v-model="username"
              name="username"
              placeholder="用户名"
              clearable
              border
              :rules="[{ required: true, message: '请填写用户名' }]"
              right-icon="user-o"
            />
            <Field
              v-model="password"
              :type="eyeStatus ? 'text' : 'password'"
              name="password"
              placeholder="密码"
              :rules="[{ required: true, message: '请填写密码' }]"
              :right-icon="eyeStatus ? 'eye-o' : 'closed-eye'"
              @click-right-icon="eyeStatus = !eyeStatus"
            />
            <div class="btn-submit">
              <Button round block type="info" native-type="submit">提交</Button>
            </div>
          </Form>
        </Tab>
        <Tab title="注册" name="regist"> </Tab>
      </Tabs> -->

      <Form class="form" @submit="login">
        <div class="field-row">
          <Field
            v-model="username"
            name="username"
            placeholder="用户名"
            clearable
            :rules="[{ required: true, message: '请填写用户名' }]"
            right-icon="user-o"
          />
        </div>
        <div class="field-row">
          <Field
            v-model="password"
            :type="eyeStatus ? 'text' : 'password'"
            name="password"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
            :right-icon="eyeStatus ? 'eye-o' : 'closed-eye'"
            @click-right-icon="eyeStatus = !eyeStatus"
          />
        </div>

        <div class="remme">
          <Checkbox v-model="remme" shape="square" icon-size="12px">
            记住我的登录状态
          </Checkbox>
        </div>

        <div class="btn-submit">
          <Button
            round
            block
            :loading="loading"
            type="primary"
            native-type="submit"
            v-waves
          >
            登 录
          </Button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Notify, /** Tab, Tabs,*/ Field, Form, Button, Checkbox } from 'vant'
import { mapActions } from 'vuex'

export default {
  components: {
    // Tab,
    // Tabs,
    Form,
    Field,
    Button,
    Checkbox,
  },
  data() {
    return {
      active: 'login',
      eyeStatus: false,
      username: '',
      password: '',
      remme: false,

      loading: false,
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'login',
    }),
    async login(user) {
      this.loading = true

      if (this.remme) {
        user.remme = this.remme
      }

      let error
      ;[error] = await to(this.loginAction(user))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
        this.loading = false
        return
      }

      this.loading = false
      this.$router.push(this.$route.query.to || { name: 'Index' })
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  .entry-component {
    margin-top: 128px;
    width: 80%;
    height: 320px;
    text-align: center;
    img {
      height: 32px;
      margin: 16px 0;
    }
    .form {
      padding: 16px 0;

      .field-row {
        height: 64px;
      }
      .btn-submit {
        margin: 32px 0;
        padding: 0 16px;
      }
      .remme {
        padding: 16px 16px 0;
        font-size: 12px;
      }
    }
  }
}
</style>
